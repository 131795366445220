import React from 'react';
import axios from 'axios';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logo from '../files/logo.png';

export function Customer() {
  let { id } = useParams();
  let history = useHistory();
  let query = new URLSearchParams(useLocation().search);

  return (
    <div className='vh-100 d-flex align-items-center'>
      <Container className='form-box shadow-sm'>
        <Row>
          <Col xs='12' md='6' className='p-5'>
            <section className='customer-header'>
              <img alt='WindowSkins' src={logo} className='img-fluid img-logo' />
              <h1 className='mt-4 mb-4'>Pricing Estimate</h1>
            </section>
            <CustomerForm id={id} query={query} history={history} />
          </Col>
          <Col xs='12' md='6' className='background-primary d-none d-md-block'>
            <h2>Help us estimate the cost of your WindowSkins.</h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      postcode: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  // API Configs
  checkConfig() {
    return {
      method: 'get',
      url: 'https://api.windowskins.co.uk/quoting/customer/read.php?id=' + this.state.id
    };
  }
  createConfig() {
    return {
      method: 'post',
      url: 'https://api.windowskins.co.uk/quoting/customer/new.php',
      data: {
        id: this.state.id,
        name: this.state.firstName,
        surname: this.state.lastName,
        phone: this.state.phone,
        email: this.state.email,
        postcode: this.state.postcode
      }
    };
  }
  updateConfig() {
    return {
      method: 'post',
      url: 'https://api.windowskins.co.uk/quoting/customer/update.php',
      data: {
        id: this.state.id,
        name: this.state.firstName,
        surname: this.state.lastName,
        phone: this.state.phone,
        email: this.state.email,
        postcode: this.state.postcode
      }
    };
  }

  // Utillities
  getDataFromURL() {
    this.setState({
      firstName: this.props.query.get('name') ? this.props.query.get('name') : '',
      lastName: this.props.query.get('surname') ? this.props.query.get('surname') : '',
      phone: this.props.query.get('phone') ? this.props.query.get('phone') : '',
      email: this.props.query.get('email') ? this.props.query.get('email') : '',
      postcode: this.props.query.get('postcode') ? this.props.query.get('postcode') : ''
    });
  }
  goToOrderPage() {
    this.props.history.push({ pathname: `/o/${this.state.id}`, state: { isCorrect: true } });
  }

  // Handlers
  handleInputChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  handleSubmit(e) {
    e.preventDefault();

    // Customer api queries
    axios(this.checkConfig())
      .then(response => {
        // Customer exists. Updating details
        axios(this.updateConfig())
          .then(response => {
            // Successfully updated customer details
            this.goToOrderPage();
          })
          .catch(error => {
            if (error.response.status === 503) {
              // Unable to update customer
              toast('Sorry! An error occured, please try again later, or contact us directly. Error code: 0x20', {
                type: 'error'
              });
            } else {
              // When other error occured
              toast('Sorry! An error occured, please try again later, or contact us directly. Error code: 0x21', {
                type: 'error'
              });
            }
          });
      })
      .catch(error => {
        if (error.response.status === 404) {
          // Customer doesn't exists, try create new one
          axios(this.createConfig())
            .then(response => {
              // Successfully created new customer
              this.goToOrderPage();
            })
            .catch(error => {
              if (error.response.status === 503) {
                // Unable to create customer
                toast('Sorry! An error occured, please try again later, or contact us directly. Error code: 0x30', {
                  type: 'error'
                });
              } else {
                // When other error occured
                toast('Sorry! An error occured, please try again later, or contact us directly. Error code: 0x31', {
                  type: 'error'
                });
              }
            });
        } else {
          // When other error occured
          toast('Sorry! An error occured, please try again later, or contact us directly. Error code: 0x40', {
            type: 'error'
          });
        }
      });
  }

  // React Component Lifecycle
  componentDidMount() {
    // Pre-fill customer data from URL address
    this.getDataFromURL();

    // Check, if customer already exists
    axios(this.checkConfig())
      .then(response => {
        // Get customer data from the server
        this.setState({
          firstName: response.data.name,
          lastName: response.data.surname,
          phone: response.data.phone,
          email: response.data.email,
          postcode: response.data.postcode
        });
      })
      .catch(error => {
        // Check type of errors
        if (error.response.status !== 404) {
          toast('Sorry! An error occured, please try again later, or contact us directly. Error code: 0x10', {
            type: 'error'
          });
        }
      });
  }
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h3>Personal Information</h3>
        <input type='text' name='customerId' hidden></input>

        <div className='form-group'>
          <label htmlFor='customerName'>
            First Name <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className='form-control'
            name='firstName'
            placeholder='Enter your name'
            value={this.state.firstName}
            onChange={this.handleInputChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='customerSurname'>
            Surname <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className='form-control'
            name='lastName'
            placeholder='Enter your surname'
            value={this.state.lastName}
            onChange={this.handleInputChange}
            required
          />
        </div>

        <h3>Contact Details</h3>
        <div className='form-group'>
          <label htmlFor='customerPhone'>
            Phone <span className='text-danger'>*</span>
          </label>
          <input
            type='phone'
            className='form-control'
            name='phone'
            placeholder='Enter your phone number'
            value={this.state.phone}
            onChange={this.handleInputChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='customerEmail'>
            Email <span className='text-danger'>*</span>
          </label>
          <input
            type='email'
            className='form-control'
            name='email'
            placeholder='Enter your email'
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='customerPostCode'>
            Postcode <span className='text-danger'>*</span>
          </label>
          <input
            type='text'
            className='form-control'
            name='postcode'
            placeholder='Enter property postcode'
            value={this.state.postcode}
            onChange={this.handleInputChange}
            required
          />
        </div>

        <input type='submit' className='btn btn-secondary' value='Step 2: Measure Windows' />
      </form>
    );
  }
}
