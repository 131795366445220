import React from 'react'

export default class WindowRoomController extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.props.onValueChange(e.target.name, e.target.value);
  }

  render() {
    const items = [
      ['kitchen', 'Kitchen'],
      ['kitchenDiner', 'Kitchen-Diner'],
      ['pantry', 'Pantry'],
      ['dinig', 'Dining Room'],
      ['living', 'Living Room'],
      ['sitting', 'Sitting Room'],
      ['lounge', 'Lounge'],
      ['drawing', 'Drawing Room'],
      ['office', 'Office'],
      ['study', 'Study'],
      ['utility', 'Utility Room'],
      ['laundry', 'Laundry Room'],
      ['playroom', 'Playroom'],
      ['snug', 'Snug'],
      ['bedroom', 'Bedroom'],
      ['master', 'Master Bedroom'],
      ['childsingle', 'Childs Bedroom Single'],
      ['childdouble', 'Childs Bedroom Double'],
      ['doughters', 'Doughters Bedroom'],
      ['sons', 'Sons Bedroom'],
      ['nursery', 'Nursery'],
      ['single', 'Bedroom Single'],
      ['double', 'Bedroom Double'],
      ['bed1', 'Bedroom 1'],
      ['bed2', 'Bedroom 2'],
      ['bed3', 'Bedroom 3'],
      ['bed4', 'Bedroom 4'],
      ['bed5', 'Bedroom 5'],
      ['bed6', 'Bedroom 6'],
      ['guest', 'Guest Bedroom'],
      ['lavatory', 'Lavatory'],
      ['bmain', 'Bathroom (Main)'],
      ['bmaster', 'Bathroom (Master En-Suite)'],
      ['bensuite', 'Bathroom (En-Suite)'],
      ['bguestensuite', 'Bathroom (Guest, En-Suite)'],
      ['bguest', 'Bathroom (Guest)'],
      ['reception', 'Reception'],
      ['staircase', 'Staircase'],
      ['hallway', 'Hallway'],
      ['landing', 'Landing'],
      ['porch', 'Porch'],
      ['Mezzanine', 'Mezzanine'],
      ['conservatory', 'Conservatory'],
      ['breakfast', 'Breakfast Room'],
      ['room1', 'Room 1'],
      ['room2', 'Room 2'],
      ['room3', 'Room 3'],
      ['room4', 'Room 4'],
      ['room5', 'Room 5'],
      ['room6', 'Room 6'],
      ['room7', 'Room 7'],
      ['room8', 'Room 8'],
      ['room9', 'Room 9'],
      ['room10', 'Room 10']
    ];

    const itemList = items.map(item => (
      <option key={item[0]} value={item[0]}>
        {item[1]}
      </option>
    ));

    return (
      <div className='form-group'>
        <label htmlFor='windowRoom'>
          Room Name <span className='text-danger'>*</span>
        </label>
        <select className='form-control' name='room' id='windowRoom' value={this.props.value} onChange={this.handleInputChange} required>
          <option value='' hidden>
            Choose the room
          </option>
          {itemList}
        </select>
      </div>
    );
  }
}