import React from 'react';
import imageCompression from 'browser-image-compression';

export default class FormPictures extends React.Component {
  constructor(props) {
    super(props);
  }

  // Utilities
  openFileInput = e => {
    e.preventDefault();
    document.querySelector('#fileInput').click();
  };
  renderDisplayPicture = (url, index) => {
    if (url.url[0] === "'") {
      return (
        url.type !== 'deleted' && (
          <div className='picture-display' key={index}>
            <div className='remove' onClick={() => this.props.handleImageRemove(url)}>
              <span>&times;</span>
            </div>
            <img
              src={`https://api.windowskins.co.uk/db/${this.props.values.customerId}${url.url.replace("'", '').replace("'", '')}`}
              alt='Window'
              className={'image-display'}
            />
          </div>
        )
      );
    } else {
      return (
        <div className='picture-display' key={index}>
          <div className='remove' onClick={() => this.props.handleImageRemove(url)}>
            <span>&times;</span>
          </div>
          <img src={url.url} alt='Window' className={'image-display'} />
        </div>
      );
    }
  };

  // Handlers
  handleImageUpload = e => {
    const imageFile = e.target.files;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    Array.from(imageFile).forEach(el => {
      imageCompression(el, options)
        .then(compressedFile => {
          // Convert compressed file to stream
          imageCompression.getDataUrlFromFile(compressedFile).then(img => {
            let temp = this.props.values.filesCopy;
            temp.push({ url: img, type: 'added' });

            this.props.onValueChange('files', temp);
            this.props.onValueChange('filesCopy', temp);
          });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  handleImageRemove = url => {
    // const filterImages = images.filter(image => image !== url);
    // setImages(filterImages);
    // onValueChange('files', filterImages);
  };

  // React Components Lifecycle
  render() {
    return (
      <section className='form-part-1'>
        <fieldset className={'unlocked'} disabled={this.props.isDisabled}>
          <input type='file' accept='image/*' id='fileInput' name='file' onChange={this.handleImageUpload} multiple hidden />

          <section className={this.props.isDisabled ? 'pictures-section disabled-pictures-section' : 'pictures-section'}>
            <div className={this.props.isDisabled ? 'btn-picture-uploader static-cursor' : 'btn-picture-uploader'} onClick={this.openFileInput}>
              <span className='plus-sign'>+</span>
              <br />
              <span className='btn-description'>Add Pictures</span>
            </div>

            {this.props.values.files.length !== 0 ? this.props.values.files.map((el, index) => this.renderDisplayPicture(el, index)) : ''}
          </section>
        </fieldset>

        <div
          className='form-check mt-2'
          hidden={
            this.props.values.files.length > 0 ||
            !this.props.values.files.filter(file => file.type === 'deleted').length === this.props.values.files.length
          }
        >
          <input
            className='form-check-input'
            type='checkbox'
            value='unable'
            id='image-controller-no'
            checked={this.props.isDisabled}
            onChange={e => {
              this.props.onValueChange('isCannotProvideChecked', e.target.checked);
            }}
          />
          <label className='form-check-label' htmlFor='image-controller-no'>
            I'm unable to provide a photo
          </label>
        </div>

        <div className='alert alert-warning mt-3' role='alert' hidden={this.props.values.fileName !== 'true'}>
          Pricing will be less accurate without a picture
        </div>
      </section>
    );
  }
}
