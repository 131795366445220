import React from 'react'

export default class WindowFormButton extends React.Component {
  constructor(props) {
    super(props);

    this.saveBtn = this.saveBtn.bind(this);
    this.editBtn = this.editBtn.bind(this);
  }

  saveBtn() {
    return <input type='submit' className='btn btn-secondary' value='Save Window' />;
  }

  editBtn() {
    return (
      <div className='btn-group w-100 mb-3' role='group'>
        <button type='button' name='update' className='btn btn-secondary mr-1' onClick={this.props.edit}>
          Update Window
        </button>
        <button type='button' name='remove' className='btn btn-warning ml-1' onClick={this.props.delete}>
          Delete Window
        </button>
      </div>
    );
  }

  render() {
    return this.props.type === 'new' ? this.saveBtn() : this.editBtn();
  }
}