import React from 'react'

export default class WindowCommentsController extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.props.onValueChange(e.target.name, e.target.value);
  }

  render() {
    return (
      <div className='form-group'>
        <label htmlFor='comments-controller'>Your Comments</label>
        <textarea
          className='form-control'
          id='comments-controller'
          name='comments'
          placeholder='Your Comments'
          rows='3'
          value={this.props.value}
          onChange={this.handleInputChange}
        />
      </div>
    );
  }
}