import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import {Row, Col} from 'react-bootstrap';
import WindowBox from './WindowBox';
import WindowAdd from './WindowAdd';

export default class WindowList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.type,
      id: this.props.window,
      sending: false
    };

    this.sendQuoteConfig = this.sendQuoteConfig.bind(this);
    this.handleQuoteSubmit = this.handleQuoteSubmit.bind(this);
  }

  // loadWindowsList() {
  //   axios({
  //     method: 'get',
  //     url: 'https://api.windowskins.co.uk/quoting/window/read.php?id=' + this.props.id
  //   })
  //     .then(response => {
  //       this.setState({
  //         windows: response.data
  //       });
  //       // console.log(this.state)
  //     })
  //     .catch(error => {
  //       console.log(error.config);
  //     });
  // }

  sendQuoteConfig() {
    return {
      method: 'post',
      url: 'https://api.windowskins.co.uk/quoting/customer/send.php',
      data: {
        id: this.props.id
      }
    };
  }

  // Handlers
  handleQuoteSubmit() {
    if (this.state.sending === false) {
      // Change state to block send quote button
      this.setState({
        sending: true
      });

      axios(this.sendQuoteConfig())
        .then(response => {
          console.log(response.data);
          this.setState({
            sending: false
          });
          toast('Your quote has been send!', {
            type: 'success'
          });
        })
        .catch(error => {
          this.setState({
            sending: false
          });
          toast('There was an issue with your quote', {
            type: 'warning'
          });
        });
    }
  }
  renderSpinner() {
    return (
      <div className='spinner-border spinner-border-sm text-light mr-3' role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    );
  }

  render() {
    return (
      <section>
        <h3>Your Windows</h3>

        <WindowAdd active={this.props.type === 'new' ? 'true' : 'false'} actionType={this.props.actionType} actionWindow={this.props.actionWindow} />

        {this.props.windows.length !== 0 ? (
          this.props.windows.map((values, index) => {
            return (
              <WindowBox
                key={index}
                values={values}
                active={this.props.type === 'edit' && values.id === this.props.window ? 'true' : 'false'}
                actionType={this.props.actionType}
                actionWindow={this.props.actionWindow}
              />
            );
          })
        ) : (
          <p className='mt-3 text-black-50'>Add your first window!</p>
        )}
        <Row className='p-3 no-gutters mt-3 send-box bg-white'>
          <Col xs='12' className='p-3'>
            <button className='btn btn-primary' onClick={this.handleQuoteSubmit}>
              {this.state.sending === true ? this.renderSpinner() : ''}
              Submit your measurements
            </button>
          </Col>
        </Row>
      </section>
    );
  }
}
