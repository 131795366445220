import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import PicturesController from './FormPictures';
import WindowFloorController from './FormFloor';
import WindowRoomController from './FormRoom';
import WindowTypeController from './FormType';
import WindowUnitController from './FormUnit';
import WindowHeightController from './FormHeight';
import WindowWidthController from './FormWidth';
import WindowCommentsController from './FormComment';
import WindowFormButton from './FormButtons';
import Spinner from './Spinner';
import { SpinnerWrapper } from '../styles/sharedStyles';

export default class WindowForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerId: this.props.id,
      id: this.props.window,
      type: '',
      location: '',
      room: '',
      floor: '',
      unit: '',
      height: '',
      width: '',
      comments: '',
      files: [],
      filesCopy: [],
      isCannotProvideChecked: false,
      isLoading: false
    };

    this.handleNewWindow = this.handleNewWindow.bind(this);
    this.handleUpdateWindow = this.handleUpdateWindow.bind(this);
    this.handleDeleteWindow = this.handleDeleteWindow.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  // API Configs
  createWindowConfig() {
    return {
      method: 'post',
      url: 'https://api.windowskins.co.uk/quoting/window/new.php',
      data: {
        customerId: this.state.customerId,
        type: this.state.type,
        location: this.state.location,
        room: this.state.room,
        floor: this.state.floor,
        unit: this.state.unit,
        height: this.state.height,
        width: this.state.width,
        comments: this.state.comments,
        files: this.state.files
      }
    };
  }
  updateWindowConfig() {
    return {
      method: 'post',
      url: 'https://api.windowskins.co.uk/quoting/window/update.php',
      data: {
        customerId: this.state.customerId,
        id: this.state.id,
        type: this.state.type,
        location: this.state.location,
        room: this.state.room,
        floor: this.state.floor,
        unit: this.state.unit,
        height: this.state.height,
        width: this.state.width,
        comments: this.state.comments,
        files: this.state.filesCopy
        // files: this.state.files
        // file_name: this.state.fileName
      }
    };
  }
  deleteWindowConfig() {
    return {
      method: 'post',
      url: 'https://api.windowskins.co.uk/quoting/window/remove.php',
      data: {
        customerId: this.state.customerId,
        id: this.state.id
      }
    };
  }
  readWindowConfig() {
    return {
      method: 'post',
      url: 'https://api.windowskins.co.uk/quoting/window/read_one.php',
      data: {
        customerId: this.props.id,
        id: this.props.window
      }
    };
  }

  // API Requests
  handleNewWindow(e) {
    e.preventDefault();

    if (this.props.type === 'new') {
      this.setState({ isLoading: true });
      axios(this.createWindowConfig())
        .then(response => {
          toast('Window Added Successfuly!', {
            type: 'success'
          });
          // Set form back to new window
          this.props.actionType('new');
          this.props.actionWindow(0);
          this.props.updateCallback();
          this.setState({ isCannotProvideChecked: false });
          this.cleanForm();
          this.setState({ isLoading: false });
        })
        .catch(error => {
          toast('An error occured while adding new Window', {
            type: 'error'
          });
          this.setState({ isLoading: false });
        });
    }
  }
  handleUpdateWindow(e) {
    e.preventDefault();
    if (this.props.type === 'edit') {
      this.setState({ isLoading: true });
      axios(this.updateWindowConfig())
        .then(response => {
          toast('Window Updated Successfully!', {
            type: 'success'
          });
          this.setState({ isLoading: false });
          (async () => {
            await this.props.updateCallback();
          })();

          axios(this.readWindowConfig())
            .then(response => {
              const fetched = response.data.pictures.map(picture => ({ ...picture, type: 'fetched' }));

              this.setState({
                customerId: response.data.customer_id,
                id: response.data.id,
                type: response.data.type,
                room: response.data.room,
                floor: response.data.floor,
                unit: response.data.unit,
                height: response.data.height,
                width: response.data.width,
                comments: response.data.comments,
                files: response.data.pictures,
                filesCopy: fetched,
                isCannotProvideChecked: response.data.pictures.length <= 0
              });
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          toast("An error occured while updating Window's details", {
            type: 'error'
          });
          this.setState({ isLoading: false });
        });
    }
  }
  handleDeleteWindow(e) {
    e.preventDefault();
    if (this.props.type === 'edit') {
      axios(this.deleteWindowConfig())
        .then(response => {
          toast('Window Removed Succefully', {
            type: 'info'
          });

          // Set form back to new window
          this.props.actionType('new');
          this.props.actionWindow(0);
          this.props.updateCallback();
          this.cleanForm();
        })
        .catch(error => {
          toast('An error occured while removing Window', {
            type: 'error'
          });
          console.log(error.config);
        });
    }
  }

  // Utilities
  handleInputChange(name, value) {
    this.setState({
      [name]: value
    });
  }
  renderHeader() {
    if (this.props.type === 'new') {
      return `Add New Window`;
    } else if (this.props.type === 'edit') {
      return `Edit Window #${this.state.id}`;
    }
  }
  cleanForm() {
    this.setState({
      customerId: this.props.id,
      id: this.props.window,
      type: '',
      room: '',
      floor: '',
      unit: '',
      height: '',
      width: '',
      comments: '',
      files: [],
      filesCopy: []
    });
  }
  checkForAvailability() {
    if (this.state.files.length > 0) {
      return true;
    } else {
      return this.state.isCannotProvideChecked;
    }
  }

  // React Component Lifecycle
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.type === 'new') {
      // Clear form
      if (this.state.id !== this.props.window) {
        this.cleanForm();
        this.setState({
          isCannotProvideChecked: false
        });
      }
    } else if (this.props.type === 'edit') {
      // Compare window ids to avoid infinite loop
      if (this.state.id !== this.props.window) {
        axios(this.readWindowConfig())
          .then(response => {
            const fetched = response.data.pictures.map(picture => ({ ...picture, type: 'fetched' }));

            this.setState({
              customerId: response.data.customer_id,
              id: response.data.id,
              type: response.data.type,
              room: response.data.room,
              floor: response.data.floor,
              unit: response.data.unit,
              height: response.data.height,
              width: response.data.width,
              comments: response.data.comments,
              files: response.data.pictures,
              filesCopy: fetched,
              isCannotProvideChecked: response.data.pictures.length <= 0
            });
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }

  handleImageRemove = url => {
    const filterImages = this.state.files.filter(image => image.url !== url.url);
    this.setState({ files: filterImages });

    //copy - added/fetched/deleted flags
    const fileCopyIndex = this.state.filesCopy.findIndex(file => file.url === url.url);
    const imagesCopy = [...this.state.filesCopy];

    if (fileCopyIndex !== -1 && imagesCopy[fileCopyIndex].type === 'added') {
      const arr = imagesCopy.filter(image => image.url !== url.url);
      this.setState({ filesCopy: arr });
    }

    if (fileCopyIndex !== -1 && imagesCopy[fileCopyIndex].type === 'fetched') {
      imagesCopy[fileCopyIndex] = { ...imagesCopy[fileCopyIndex], type: 'deleted' };
      this.setState({ filesCopy: imagesCopy });
    }
  };

  render() {
    return (
      <section className='window-form'>
        <h1 className='mt-4 mb-3'>{this.renderHeader()}</h1>
        <h3>1. Upload Window's Pictures</h3>
        <PicturesController
          type={this.props.type}
          onValueChange={this.handleInputChange}
          isDisabled={this.state.isCannotProvideChecked}
          values={this.state}
          handleImageRemove={this.handleImageRemove}
        />
        {/* ========== Form Part 2 ========== */}
        <section className='form-part-2 mt-3'>
          <h3>2. Tell us more about this window</h3>
          <form onSubmit={this.handleNewWindow}>
            {this.state.isLoading ? (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            ) : (
              <fieldset className={this.checkForAvailability() === false ? '' : 'unlocked'} disabled={!this.checkForAvailability()}>
                <WindowFloorController value={this.state.floor} onValueChange={this.handleInputChange} />
                <WindowRoomController value={this.state.room} onValueChange={this.handleInputChange} />
                <WindowTypeController value={this.state.type} onValueChange={this.handleInputChange} />
                <WindowUnitController value={this.state.unit} onValueChange={this.handleInputChange} />
                <WindowHeightController value={this.state.height} onValueChange={this.handleInputChange} />
                <WindowWidthController value={this.state.width} onValueChange={this.handleInputChange} />
                <WindowCommentsController value={this.state.comments} onValueChange={this.handleInputChange} />

                <WindowFormButton type={this.props.type} edit={this.handleUpdateWindow} delete={this.handleDeleteWindow} />
              </fieldset>
            )}
          </form>
        </section>
      </section>
    );
  }
}
