import React from 'react'

export default class WindowTypeController extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.props.onValueChange(e.target.name, e.target.value);
  }

  render() {
    const items = [
      ['Sash', 'The one which slide up and down'],
      ['Casement', 'The ones which open outwards'],
      ['Door', 'Includes French Doors'],
      ['Other', 'Leaded Lights, Crittal, Bascule etc.']
    ];

    const itemList = items.map(item => (
      <div key={item[0]} className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          name='type'
          id={'windowType-' + item[0]}
          value={item[0]}
          checked={this.props.value === item[0] ? true : false}
          onChange={this.handleInputChange}
          required
        />
        <label className='form-check-label' htmlFor={'windowType-' + item[0]} title={item[1]}>
          {item[0]} <span className='help-button ml-1'>?</span>
        </label>
      </div>
    ));

    return (
      <div className='form-group field-filled'>
        <label>
          Window Type <span className='text-danger'>*</span>
        </label>
        <br></br>
        {itemList}
      </div>
    );
  }
}