import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';

import { Customer } from './routes/Customer';
import { Order } from './routes/Order';
import Landing from './routes/Landing';
import SEO from './components/SEO';

function App() {
  document.title = 'WindowSkins Online Quoting';
  return (
    <Suspense fallback={<div>Wczytywanie...</div>}>
      <SEO />
      <Router>
        <Switch>
          <Route path={'/'} exact component={Landing} />
          <Route path='/c/:id' component={Customer} />
          <Route path='/o/:id' component={Order} />
          <Route component={Landing} />
        </Switch>
      </Router>
      <ToastContainer
        position='bottom-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
    </Suspense>
  );
}

export default App;
