import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class WindowAddButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  isActive() {
    if (this.props.active === 'true') {
      return 'bg-white shadow-sm p-3 no-gutters mt-3 window-box read';
    } else {
      return 'bg-white shadow-sm p-3 no-gutters mt-3 window-box';
    }
  }

  handleClick(e) {
    e.preventDefault();
    this.props.actionType('new');
    this.props.actionWindow(0);
  }

  render() {
    return (
      <Row className={this.isActive()} onClick={this.handleClick}>
        <Col>+ Add Another Window</Col>
      </Row>
    );
  }
}