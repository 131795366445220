import React from 'react';
import { Helmet } from 'react-helmet';

function SEO() {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
    >
      <meta property='og:type' content='business.business' />
      <meta property='og:title' content='WindowSkins Online Quoting System' />
      <meta property='og:url' content='https://quoting.windowskins.co.uk' />
      <meta
        property='og:image'
        content='https://scontent-waw1-1.xx.fbcdn.net/v/t1.0-9/21106368_1374096912703252_3193680911478000639_n.jpg?_nc_cat=110&_nc_sid=e3f864&_nc_ohc=ZzP_4BziGtEAX_ho_Ni&_nc_ht=scontent-waw1-1.xx&oh=d153a4c34a5eef2bb4c24b13f23b9e4f&oe=5F84E523'
      />
      <meta property='business:contact_data:street_address' content='Kemp House, 152 City Road' />
      <meta property='business:contact_data:locality' content='London' />
      <meta property='business:contact_data:region' content='Greater London' />
      <meta property='business:contact_data:postal_code' content='EC1V 2NX' />
      <meta property='business:contact_data:country_name' content='United Kingdom' />
    </Helmet>
  );
}

export default SEO;
