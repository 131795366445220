import React from 'react';
import { Form, Formik } from 'formik';

const Landing = ({ history }) => {
  const handleSubmit = ({ id }) => {
    history.push(`/c/${id}`);
  };
  return (
    <Formik initialValues={{ id: '' }} onSubmit={handleSubmit}>
      {({ handleChange, values }) => (
        <Form className={'wrapper'}>
          <div className='form-group'>
            <h2 style={{ fontSize: '24px' }} className={'mb-4'}>
              Please, provide user ID to continue
            </h2>
            <input
              type='text'
              className='form-control'
              name='id'
              id={'id'}
              placeholder='Enter user ID'
              value={values.id}
              onChange={handleChange}
              required
            />
            <button type='submit' className='btn btn-secondary mt-4'>
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Landing;
