import React from 'react'

export default class WindowFloorController extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.props.onValueChange(e.target.name, e.target.value);
  }

  render() {
    const items = [
      [-1, 'Basement (-1)'],
      [-0.5, 'Basement Staircase (-0.5)'],
      [0, 'Ground Floor (0)'],
      [0.5, 'Ground Staircase (0.5)'],
      [1, 'First Floor (1)'],
      [1.5, 'First Staircase (1.5)'],
      [2, 'Second Floor (2)'],
      [2.5, 'Second Staircase (2.5)'],
      [3, 'Third Floor (3)'],
      [3.5, 'Third Staircase (3.5)'],
      [4, 'Fourth Floor (4)'],
      [4.5, 'Fourth Staircase (4.5)'],
      [5, 'Fifth Floor (5)'],
      [5.5, 'Fifth Staircase (5.5)'],
      [6, 'Sixth Floor (6)'],
      [6.5, 'Sixth Staircase (6.5)'],
      [7, 'Seventh Floor (7)'],
      [7.5, 'Seventh Staircase (7.5)']
    ];

    const itemList = items.map(item => (
      <option key={item[0]} value={item[0]}>
        {item[1]}
      </option>
    ));

    return (
      <div className='form-group'>
        <label htmlFor='windowFloor'>
          Floor <span className='text-danger'>*</span>
        </label>
        <select className='form-control' name='floor' id='windowFloor' value={this.props.value} onChange={this.handleInputChange} required>
          <option value='' hidden>
            Choose the floor
          </option>
          {itemList}
        </select>
      </div>
    );
  }
}
