import React from 'react'

export default class WindowHeightController extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.props.onValueChange(e.target.name, e.target.value);
  }

  render() {
    return (
      <div className='form-group'>
        <label htmlFor='height-controller'>
          Height <span className='text-danger'>*</span>
        </label>
        <input
          type='number'
          className='form-control'
          id='height-controller'
          name='height'
          placeholder='Enter window height'
          step='0.01'
          value={this.props.value}
          onChange={this.handleInputChange}
          required
        />
      </div>
    );
  }
}