import React from 'react'

export default class WindowUnitController extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.props.onValueChange(e.target.name, e.target.value);
  }

  render() {
    const items = [
      ['cm', 'Centimetres'],
      ['inch', 'Inches']
    ];

    const itemList = items.map(item => (
      <div key={item[0]} className='form-check form-check-inline'>
        <input
          className='form-check-input'
          type='radio'
          name='unit'
          id={'unit-' + item[0]}
          value={item[0]}
          checked={this.props.value === item[0] ? true : false}
          onChange={this.handleInputChange}
          required
        />
        <label className='form-check-label' htmlFor={'unit-' + item[0]}>
          {item[1]}
        </label>
      </div>
    ));

    return (
      <div className='form-group field-filled'>
        <label>
          Measuring Unit <span className='text-danger'>*</span>
        </label>
        <br></br>
        {itemList}
      </div>
    );
  }
}