import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import missing from '../files/missing2.gif';

export default class WindowBox extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.pictureSrc = this.pictureSrc.bind(this);
  }

  // Utillities
  isActive() {
    if (this.props.active === 'true') {
      return 'bg-white shadow-sm p-3 no-gutters mt-3 window-box read';
    } else {
      return 'bg-white shadow-sm p-3 no-gutters mt-3 window-box';
    }
  }
  pictureSrc(picture) {
    const url = picture.replace(/'/g, '');
    return `https://api.windowskins.co.uk/db/${this.props.values.customer_id}${url}`;

    // if (this.props.values.file_name === 'true') {
    //   return missing;
    // } else if (this.props.values.file_name !== '' && this.props.values.file_name !== true) {
    //   return `https://api.windowskins.co.uk/${this.props.values.file_name.slice(6)}`;
    // } else {
    //   return missing;
    // }
  }
  floorName(number) {
    switch (number) {
      case '-1':
        return 'Basement (-1)';
      case '-0.5':
        return 'Basement Staircase (-0.5)';
      case '0':
        return 'Ground Floor (0)';
      case '0.5':
        return 'Ground Staircase (0.5)';
      case '1':
        return 'First Floor (1)';
      case '1.5':
        return 'First Staircase (1.5)';
      case '2':
        return 'Second Floor (2)';
      case '2.5':
        return 'Second Staircase (2.5)';
      case '3':
        return 'Third Floor (3)';
      case '3.5':
        return 'Third Staircase (3.5)';
      case '4':
        return 'Fourth Floor (4)';
      case '4.5':
        return 'Fourth Staircase (4.5)';
      case '5':
        return 'Fifth Floor (5)';
      case '5.5':
        return 'Fifth Staircase (5.5)';
      case '6':
        return 'Sixth Floor (6)';
      case '6.5':
        return 'Sixth Staircase (6.5)';
      case '7':
        return 'Seventh Floor (7)';
      case '7.5':
        return 'Seventh Staircase (7.5)';
      default:
        return '';
    }
  }
  roomName(slug) {
    switch (slug) {
      case 'kitchen':
        return 'Kitchen';
      case 'kitchenDiner':
        return 'Kitchen-Diner';
      case 'pantry':
        return 'Pantry';
      case 'dinig':
        return 'Dining Room';
      case 'living':
        return 'Living Room';
      case 'sitting':
        return 'Sitting Room';
      case 'lounge':
        return 'Lounge';
      case 'drawing':
        return 'Drawing Room';
      case 'office':
        return 'Office';
      case 'study':
        return 'Study';
      case 'utility':
        return 'Utility Room';
      case 'laundry':
        return 'Laundry Room';
      case 'playroom':
        return 'Playroom';
      case 'snug':
        return 'Snug';
      case 'bedroom':
        return 'Bedroom';
      case 'master':
        return 'Master Bedroom';
      case 'childsingle':
        return 'Childs Bedroom Single';
      case 'childdouble':
        return 'Childs Bedroom Double';
      case 'doughters':
        return 'Doughters Bedroom';
      case 'sons':
        return 'Sons Bedroom';
      case 'nursery':
        return 'Nursery';
      case 'single':
        return 'Bedroom Single';
      case 'double':
        return 'Bedroom Double';
      case 'bed1':
        return 'Bedroom 1';
      case 'bed2':
        return 'Bedroom 2';
      case 'bed3':
        return 'Bedroom 3';
      case 'bed4':
        return 'Bedroom 4';
      case 'bed5':
        return 'Bedroom 5';
      case 'bed6':
        return 'Bedroom 6';
      case 'guest':
        return 'Guest Bedroom';
      case 'lavatory':
        return 'Lavatory';
      case 'bmain':
        return 'Bathroom (Main)';
      case 'bmaster':
        return 'Bathroom (Master En-Suite)';
      case 'bensuite':
        return 'Bathroom (En-Suite)';
      case 'bguestensuite':
        return 'Bathroom (Guest, En-Suite)';
      case 'bguest':
        return 'Bathroom (Guest)';
      case 'reception':
        return 'Reception';
      case 'staircase':
        return 'Staircase';
      case 'hallway':
        return 'Hallway';
      case 'landing':
        return 'Landing';
      case 'porch':
        return 'Porch';
      case 'Mezzanine':
        return 'Mezzanine';
      case 'conservatory':
        return 'Conservatory';
      case 'breakfast':
        return 'Breakfast Room';
      case 'room1':
        return 'Room 1';
      case 'room2':
        return 'Room 2';
      case 'room3':
        return 'Room 3';
      case 'room4':
        return 'Room 4';
      case 'room5':
        return 'Room 5';
      case 'room6':
        return 'Room 6';
      case 'room7':
        return 'Room 7';
      case 'room8':
        return 'Room 8';
      case 'room9':
        return 'Room 9';
      case 'room10':
        return 'Room 10';
      default:
        return '';
    }
  }

  // Handlers
  handleClick(e) {
    e.preventDefault();
    this.props.actionType('edit');
    this.props.actionWindow(this.props.values.id);
  }

  // React Component Lifecycle
  render() {
    return (
      <Row className={this.isActive()} onClick={this.handleClick}>
        <Col xs='4'>
          {this.props.values.pictures.length > 0 ? (
            <img src={this.pictureSrc(this.props.values.pictures[0].url)} alt={`Window-${this.props.values.id}`} className='img-fluid pr-3' />
          ) : (
            <img src={missing} alt={`Window-${this.props.values.id}`} className='img-fluid pr-3' />
          )}
        </Col>
        <Col xs='8' className='d-flex flex-column justify-content-center'>
          <p>
            <small>
              {this.floorName(this.props.values.floor)} | {this.roomName(this.props.values.room)}
            </small>
          </p>
          <h4 className='pb-0 mb-0 text-capitalize'>
            {this.props.values.type} <span className='text-light-grey ml-1'>#{this.props.values.id}</span>
          </h4>
          <p>
            {this.props.values.height} {this.props.values.unit} high x {this.props.values.width} {this.props.values.unit} width
          </p>
          <p>
            <small>{this.props.values.comments}</small>
          </p>
          <p className='text-right text-muted font-weight-bold edit-link-on-tile'>
            <small className='font-weight-bold'>(Click to edit)</small>
          </p>
        </Col>
      </Row>
    );
  }
}