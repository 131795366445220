import React, { useEffect, useState } from 'react';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

import Container from 'react-bootstrap/Container';
import { useParams } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logo from '../files/logo.png';
import { toast } from 'react-toastify';

import WindowList from '../components/WindowList';
import WindowForm from '../components/WindowForm';

export function Order({ location, history }) {
  let { id } = useParams();
  let [window, setWindow] = useState(1);
  let [type, setType] = useState('new');
  const [windows, setWindows] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchWindows = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`https://api.windowskins.co.uk/quoting/window/read.php?id=${id}`);
      setWindows(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchWindows();
    })();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!location.state || !location.state.isCorrect) {
      setLoading(false);
      history.push(`/c/${id}`);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Container className='form-box shadow-sm'>
      <Row>
        <Col xs='12' md='6' className='p-5 pt-5 pb-3'>
          <section className='customer-header'>
            <img src={logo} alt='WindowSkins' className='img-fluid img-logo' />
          </section>
          <WindowForm id={id} window={window} type={type} actionType={setType} actionWindow={setWindow} updateCallback={fetchWindows} />
        </Col>
        {!isLoading && (
          <Col xs='12' md='6' className='background-windows p-5'>
            <WindowList id={id} window={window} type={type} actionType={setType} actionWindow={setWindow} windows={windows} />
          </Col>
        )}
      </Row>
    </Container>
  );
}

class WindowFormPart3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkbox: false,
      id: this.props.values.id,
      images: []
    };

    this.pictureSrc = this.pictureSrc.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.handleImageCheckbox = this.handleImageCheckbox.bind(this);
    this.handleImageRemove = this.handleImageRemove.bind(this);
  }

  // Utilities
  openFileInput(e) {
    e.preventDefault();
    document.querySelector('#fileInput').click();
  }
  pictureSrc() {
    if (this.props.values.fileName[0] === '.') {
      return `https://api.windowskins.co.uk/${this.props.values.fileName.slice(6)}`;
    } else {
      return this.props.values.fileName;
    }
  }

  // Handlers
  handleImageUpload(e) {
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    console.log(imageFile);

    imageCompression(imageFile, options)
      .then(compressedFile => {
        // Convert compressed file to stream
        imageCompression.getDataUrlFromFile(compressedFile).then(img => {
          // this.props.onValueChange('fileName', img)
          console.log(img);
          this.state.images.push(img);
          console.log(this.state);
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  handleImageCheckbox(e) {
    this.props.onValueChange('fileName', e.target.checked ? 'true' : '');
    this.setState({
      checkbox: e.target.checked
    });
  }
  handleImageRemove(e) {
    e.preventDefault();
    this.props.onValueChange('fileName', '');
  }

  // Renders
  renderDisplayPicture(url, index) {
    return (
      <div className='picture-display' key={index}>
        <div className='remove'>
          <span>&times;</span>
        </div>
        <img src={url} alt='Window' />
      </div>
    );
  }

  // React Components Lifecycle
  componentDidUpdate() {
    if (this.state.id !== this.props.values.id) {
      if (this.props.type === 'new') {
        this.setState({
          checkbox: false,
          id: this.props.values.id
        });
      } else if (this.props.type === 'edit') {
        this.setState({
          checkbox: this.props.values.fileName === 'true',
          id: this.props.values.id
        });
      }
    }
  }

  render() {
    console.log(this.state);
    return (
      <section className='form-part-1'>
        <input type='file' accept='image/*' id='fileInput' name='file' onChange={this.handleImageUpload} multiple hidden />

        <section className='pictures-section'>
          <div className='btn-picture-uploader' onClick={this.openFileInput}>
            <span className='plus-sign'>+</span>
            <br />
            <span className='btn-description'>Add Pictures</span>
          </div>

          {this.state.images.map((el, index) => {
            return this.renderDisplayPicture(el, index);
          })}
        </section>

        <div className='form-check mt-2' hidden={this.state.images.length > 0 ? 'true' : ''}>
          <input
            className='form-check-input'
            type='checkbox'
            value='unable'
            id='image-controller-no'
            checked={this.state.checkbox}
            onChange={this.handleImageCheckbox}
          />
          <label className='form-check-label' htmlFor='image-controller-no'>
            I'm unable to provide a photo
          </label>
        </div>

        <div className='alert alert-warning mt-3' role='alert' hidden={this.props.values.fileName !== 'true'}>
          Pricing will be less accurate without a picture
        </div>
      </section>
    );
  }
}
